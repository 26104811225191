import * as mutations from "./mutations";
import * as actions from "./actions";
const state = {
    selectedOrg: JSON.parse(sessionStorage.getItem('selectedorg'))
}

export default {
    state,
    mutations,
    actions
  };