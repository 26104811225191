// export const enumInvoice = {
//     DRAFT: 'DRAFT',
//     SUBMITTED: 'SUBMITTED',
//     AUTHORISED: 'AUTHORISED'
// };

export const enumPurchaseOrder = {
    DRAFT: 'DRAFT',
    SUBMITTED: 'SUBMITTED',
    APPROVED: 'APPROVED'
};

export const enumDepreciationMethods = {
    NoDepreciation: 'No Depreciation',
    StraightLine: 'Straight Line',
    ReducingBalance: 'Reducing Balance',
    FullDepreciationAtPurchase:'Full Depreciation at Purchase'
  };

  export const enumAveragingMethod = {
    FullMonth : 'Full Month',
    ActualDays : 'Actual Days'
  }

  export const enumRegion = {
    Eastside  : 'Eastside',
    North  : 'North',
    South : 'South',
    Westcoast  : 'West Coast'
  }

  export const enumAssetStatus = {
    Draft: 'Draft',
    Registered: 'Registered',
    Disposed: 'Disposed'
};

export const ReportType = {
    AccountTransaction:'AccountTransaction',
    ItemDetails:'ItemDetails',
    ItemSummary:'ItemSummary',
    SalesByItem:'SalesByItem',
    AccountSummary:'AccountSummary',
    BankReconciliation:'BankReconciliation',
    BankSummary:'BankSummary',
    InventoryItemList:'InventoryItemList',
    ReconciliationReport:'ReconciliationReport',
    AgedReceivableSummary:'AgedReceivableSummary',
    AgedReceivableDetail:'AgedReceivableDetail',
    AgedPayableDetail:'AgedPayableDetail',
    AgedPayableSummary:'AgedPayableSummary',
  
}

export const subscriptionType = {
 SoleTrader:'SOLE',
 SmallBusiness : 'SMALL'
}
