import { reactive } from 'vue'
export const userStore = reactive({
    activeUser: JSON.parse(localStorage.getItem('activeUser')) ?? null,
    organisations:[],
    activeUserId:null,

    async setStoreValue(data){
        this.activeUser = data;
    },
    setUserId(data){
        this.activeUserId = data;
    },
    setOrganisations(data){
        this.organisations = data;
    }
  })